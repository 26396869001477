import get from 'lodash.get'

class TrackingData {
    getData () {
        const data = {
            userAgent: this.getUserAgent(),
        }

        if (this.getTrackingId()) {
            data.gclid = this.getGoogleAdsIds('gclid')
            data.gclidExpiryDate = this.getGoogleAdsIds('gclid_expiry_date')
        }


        if (this.getTrackingId() && this.isGoogleAnalyticsEnabled()) {
            data.googleAnalyticsCustomerId = this.getGaClientId()
            data.screenResolution = this.getGaScreenResolution()
            data.viewportSize = this.getGaViewportSize()
        }

        return data
    }

    getGaClientId () {
        return this.getProperty('clientId')
    }

    getGaScreenResolution () {
        return this.getProperty('screenResolution')
    }

    getGaViewportSize () {
        return this.getProperty('viewportSize')
    }

    getTrackingId () {
        return get(window, 'config.GA_TRACKING_ID')
    }

    getUserAgent () {
        return navigator.userAgent
    }

    getProperty (property) {
        const trackers = window.ga.getAll()

        let i, len

        for (i = 0, len = trackers.length; i < len; i += 1) {
            if (trackers[i].get('trackingId') === this.getTrackingId()) {
                return trackers[i].get(property)
            }
        }
    }

    isGoogleAnalyticsEnabled () {
        return !!(window.ga && typeof window.ga.getAll === 'function')
    }

    getGoogleAdsIds (value) {
        let res = '';

        let cookieName;

        if (value.includes('gclid')) {
            cookieName = '_gcl_aw';
        } else if (value.includes('wbraid')) {
            cookieName = '_gcl_gb';
        }
        const cookie = document.cookie.match(new RegExp(`(^| )${ cookieName }=([^;]+)`));

        if (cookie) {
            if (value.includes('expiry_date')) {
                res = new Date(cookie[2].split('.')[1] * 1000 + 864E5 * 90).toISOString();
            } else {
                res = cookie[2].split('.')[2];
            }
        }

        return res;
    }
}

export default new TrackingData()
